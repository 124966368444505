import { initBceCore } from '@bce/core';
import { BceButton } from '@bce/core/dist/components/bce-button';
import { BceCard } from '@bce/core/dist/components/bce-card';
import { BceDialog } from '@bce/core/dist/components/bce-dialog';
import { BceIcon } from '@bce/core/dist/components/bce-icon';
import { BceRipple } from '@bce/core/dist/components/bce-ripple';
import { BceRoot } from '@bce/core/dist/components/bce-root';
import { BceTooltip } from '@bce/core/dist/components/bce-tooltip';
import '@bce/core/dist/bce/bce.css';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import ReactDOM from 'react-dom';

async function main() {
  initBceCore()
    .addElement(
      BceButton,
      BceCard,
      BceDialog,
      BceIcon,
      BceRipple,
      BceRoot,
      BceTooltip,
    )
    .addIcon(fas);

  const el = document.getElementById('root');
  const { App } = await import('./layout/App');
  ReactDOM.render(<App />, el);
}

main();
